exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-categories-and-clusters-js": () => import("./../../../src/pages/about/categoriesAndClusters.js" /* webpackChunkName: "component---src-pages-about-categories-and-clusters-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-research-and-publications-js": () => import("./../../../src/pages/about/researchAndPublications.js" /* webpackChunkName: "component---src-pages-about-research-and-publications-js" */),
  "component---src-pages-about-resources-js": () => import("./../../../src/pages/about/resources.js" /* webpackChunkName: "component---src-pages-about-resources-js" */),
  "component---src-pages-about-website-and-book-variations-js": () => import("./../../../src/pages/about/websiteAndBookVariations.js" /* webpackChunkName: "component---src-pages-about-website-and-book-variations-js" */),
  "component---src-pages-about-whats-in-skillsmap-js": () => import("./../../../src/pages/about/whatsInSkillsmap.js" /* webpackChunkName: "component---src-pages-about-whats-in-skillsmap-js" */),
  "component---src-pages-about-why-skillsmap-js": () => import("./../../../src/pages/about/whySkillsmap.js" /* webpackChunkName: "component---src-pages-about-why-skillsmap-js" */),
  "component---src-pages-for-careers-professionals-index-js": () => import("./../../../src/pages/forCareersProfessionals/index.js" /* webpackChunkName: "component---src-pages-for-careers-professionals-index-js" */),
  "component---src-pages-for-careers-professionals-skillsmap-and-careers-education-js": () => import("./../../../src/pages/forCareersProfessionals/skillsmapAndCareersEducation.js" /* webpackChunkName: "component---src-pages-for-careers-professionals-skillsmap-and-careers-education-js" */),
  "component---src-pages-for-educators-index-js": () => import("./../../../src/pages/forEducators/index.js" /* webpackChunkName: "component---src-pages-for-educators-index-js" */),
  "component---src-pages-for-educators-using-skillsmap-with-learners-js": () => import("./../../../src/pages/forEducators/usingSkillsmapWithLearners.js" /* webpackChunkName: "component---src-pages-for-educators-using-skillsmap-with-learners-js" */),
  "component---src-pages-for-learners-js": () => import("./../../../src/pages/forLearners.js" /* webpackChunkName: "component---src-pages-for-learners-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-skillsmap-tool-categories-[category]-js": () => import("./../../../src/pages/skillsmapTool/categories/[category].js" /* webpackChunkName: "component---src-pages-skillsmap-tool-categories-[category]-js" */),
  "component---src-pages-skillsmap-tool-categories-index-js": () => import("./../../../src/pages/skillsmapTool/categories/index.js" /* webpackChunkName: "component---src-pages-skillsmap-tool-categories-index-js" */),
  "component---src-pages-skillsmap-tool-clusters-[cluster]-js": () => import("./../../../src/pages/skillsmapTool/clusters/[cluster].js" /* webpackChunkName: "component---src-pages-skillsmap-tool-clusters-[cluster]-js" */),
  "component---src-pages-skillsmap-tool-clusters-index-js": () => import("./../../../src/pages/skillsmapTool/clusters/index.js" /* webpackChunkName: "component---src-pages-skillsmap-tool-clusters-index-js" */),
  "component---src-pages-skillsmap-tool-index-js": () => import("./../../../src/pages/skillsmapTool/index.js" /* webpackChunkName: "component---src-pages-skillsmap-tool-index-js" */),
  "component---src-pages-skillsmap-tool-search-search-results-js": () => import("./../../../src/pages/skillsmapTool/search/[...searchResults].js" /* webpackChunkName: "component---src-pages-skillsmap-tool-search-search-results-js" */),
  "component---src-pages-skillsmap-tool-skills-[skill]-js": () => import("./../../../src/pages/skillsmapTool/skills/[skill].js" /* webpackChunkName: "component---src-pages-skillsmap-tool-skills-[skill]-js" */),
  "component---src-pages-skillsmap-tool-skills-index-js": () => import("./../../../src/pages/skillsmapTool/skills/index.js" /* webpackChunkName: "component---src-pages-skillsmap-tool-skills-index-js" */)
}

